import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Payout from "../components/pressroomLayout"
import PropTypes from "prop-types"
import { removeTrailingSlash } from "../utils/gatsby-node-helpers"

 const BittorrentNews = ({ data }) => {
  return (
    <Payout>
      {
        data.allWordpressPost.edges.map(({ node }) => {
          let link = `/blog` + decodeURI(node.path)
          link = removeTrailingSlash(link)
          return (
            <div className="col-12 p-0 m-0" key={node.wordpress_id}>
              <Link className="text-decoration-none" to={link} >
               <h4 className="h5 text-body" dangerouslySetInnerHTML={{ __html: node.title }} />
              </Link>
              <div className="text-muted d-block mb-4">{node.date}</div>
            </div>
          )
        })
      }
    </Payout>
  )
}
export default props => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPost(
          filter: {
            categories: {elemMatch: {name: {eq: "News"}}}, 
            tags: {elemMatch: {name: {eq: "pressroom"}}}}, 
            sort: {fields: date, order: DESC}) 
        {
          edges {
            node {
              title
              date(formatString: "MMMM DD, YYYY")
              wordpress_id
              path
            }
          }
        }
      }
    `}
    render={data => <BittorrentNews data={data} {...props} />}
  />
)

BittorrentNews.propTypes = {
    data: PropTypes.shape({
      allWordpressPost: PropTypes.shape({
        edges: PropTypes.shape({
          node: PropTypes.shape({
            title: PropTypes.string.isRequired,
            date: PropTypes.string.isRequired,
            wordpress_id: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}