import React from "react"
import LocalizedLink from "../../components/localizedLink"
import useTranslations from "../../utils/useTranslations"
import IntheNews from "../../components/inthenews"
import BittorrentNews from "../../components/news"
import heroBg from '../../images/company/adobe-stock-278687681.jpg'


const PressRoom = ({ pageContext: {text, locale} }) => {

const t = useTranslations(text)
const topSectionContainer = {
  backgroundImage:`url(${heroBg})` ,
  backgroundSize:`cover`
}
  return (
    <>
      <div className="top-section-container" style={topSectionContainer}>
        <div className="text-center py-5" >
        <div className="container py-5" >
          <h1 className="h1 m-0 pt-5 pb-4 text-white">{t(`BitTorrent News`)}</h1>
        </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row align-items-start">
          <div className="col-md-8 pt-4 pr-5">
            <BittorrentNews />
          </div>
          <div className="col-md-4">
            <div className="mb-3 pr-5 pl-5 pt-4 alert alert-secondary">
              <h3 className="h5 mb-1">{t(`About BitTorrent`)}</h3>
              <LocalizedLink id="company-pressroom-aboutus" className="text-decoration-none text-secondary" to="/company/about-us/">
                {t(`About Us`)}
              </LocalizedLink>
              <h3 className="h5 mb-1 mt-4">{t(`Press Contact`)}</h3>
              <a href="mailto:press@bittorrent.com" id="company-pressroom-emaillink" className="text-decoration-none text-secondary" rel="noopener noreferrer">
                press@bittorrent.com
              </a>
              <h3 className="h5 mb-1 mt-4">{t(`Press-kit`)}</h3>
              <a href="/bittorrent_press_kit.zip" id="company-pressroom-presskit" className="text-decoration-none text-secondary" rel="noopener noreferrer">
                <div className="mb-4">{t(`Get the Press Kit`)}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PressRoom

